.second-row{
    gap: 10px;
}
.report__mid-form-container--1 {
    top: 131px;
}
.report__mid-form-container--2 {
    top: 322px !important;
}
.report__mid-form-container--5 {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 258px;
}
.report__mid-form-container--2 {
    top: 329px;
}
.report__images {
    position: absolute;
    z-index: 4;
    top: 149px;
    right: 15px;
    width: 330px;
    background-color: #c2bfbf;
    padding: 5px 15px;
}
.report__mid-form-container--4 {
    top: 516px;
}
.report__pie-chart {
    position: absolute;
    right: 15px;
    top: 388px;
    z-index: 4;
    width: 350px;
}
.report__findingsninterpretation {
    position: absolute;
    left: 14px;
    width: 567px;
    height: 303px;
    top: 518px;
    background-color: transparent;
    display: grid;
    border: 1px solid black;
    display: grid;
}
.report__mid-form-container--3 {
    top: 455px;
}
.afib-container {
    width: 73%;
    font-size: 12.1px;
    margin-top: 0px;
}
.report__form-container{
    /* background-color: green; */
    top: 73.34px;
}


.report__inverse-header {
    font-family: "Bookman Old Style";
    background-color: white;
    color: black;
    font-size: 12.1px;
    align-self: flex-end;
    padding: 0.5px 3px;
}
.second-row {
    /* grid-gap: 10px; */
    gap: 0px;
}
.report__analysis-time {
    position: absolute;
    top: 52px;
    left: 459px;
}
.report__image {
    width: 100%;
    height: 85px;
}