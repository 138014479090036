.report-footer {
  position: absolute;
  background-color: black;
  position: absolute;
  left: 14px;
  width: 567px;
  height: 20px;
  top: 801px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;

  p {
    font-family: "Bookman Old Style Bold";
    color: white;
    font-size: 10.1px;
  }
}
