.report-header {
  position: absolute;
  left: 14px;
  width: 567px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  border-bottom: 2px solid black;
  margin-top: 10px;

  p {
    font-family: Times, serif;
    color: black;
    font-size: 12.1px;
  }
}
