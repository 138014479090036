/* Table Container */
.table {
  margin-left: 17px;
  position: relative;
  width: 94%;
  overflow: hidden;
}
  
  /* Table Popup Styling */
  .table__row-popup {
    position: absolute;
    background-color: #fff;
   
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 5px;
    z-index: 1000;
    max-width: 100px; /* Limit popup width */
  }
  
  .table__row-popup button {
    display: block;
    width: 100%;
    padding: 5px;
    margin: 2px 0;
    background-color: #3196e9;
    border: none;
    cursor: pointer;
  }
  
  .table__row-popup button:hover {
    background-color: #085ea5;
  }
  
  /* Hide overflow of the table to constrain the popup inside */
  .table__table {
    overflow: hidden;
    width: 100%;
    table-layout: fixed;
  }


  