b@font-face {
  font-family: "Bookman Old Style";
  src: local("Bookman Old Style"),
    url(./assets/fonts/BOOKOS.TTF) format("truetype");
}
@font-face {
  font-family: "Bookman Old Style Bold";
  src: local("Bookman Old Style Bold"),
    url(./assets/fonts/BOOKOSB.TTF) format("truetype");
}
@font-face {
  font-family: "Bookman Old Style Bold Italic";
  src: local("Bookman Old Style Bold Italic"),
    url(./assets/fonts/BOOKOSBI.TTF) format("truetype");
}
@font-face {
  font-family: "Bookman Old Style Italic";
  src: local("Bookman Old Style Italic"),
    url(./assets/fonts/BOOKOSI.TTF) format("truetype");
}

body {
  margin: 0;
  font-family: Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.separator {
  font-family: Times, serif;
  height: 14px;
  margin-right: 3px;
  overflow: hidden;
}
