.input {
  display: flex;

  &__label {
    display: flex;
    font-size: 12.1px;
    margin-right: 10px;

    span:first-child {
      font-family: inherit;
      flex: 1;
    }
  }

  &__input {
    background: transparent;

    font-size: 12.1px;
    color: rgb(55, 52, 53);
    outline: none;
    border: none;
  }
}
