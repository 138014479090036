.table {
  position: relative;
  z-index: 2;
  &__table {
    font-family: Times, serif;
    font-size: 8px;
    border-collapse: collapse;
    border-spacing: 0;
  }

  &__header {
    font-size: 9px;
    font-family: Times, serif;
    text-align: center;
  }
  // border-collapse: collapse;

  &__row-shade {
    background-color: rgb(167, 167, 167);
  }

  th {
    font-weight: normal;
    padding: 0px;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 0px solid black;
    overflow: hidden;

    div {
      margin: 0 auto;
    }

    &:first-child {
      border-left: 1px solid black;
    }
  }

  tr {
    margin: 0;
    padding: 0;

    &:last-child {
      td {
        border-bottom: 1px solid black;
      }
    }

    td {
      padding-left: 2px;
      margin: 0;
      border-right: 1px solid black;
      border-top: 1px solid black;
      border-bottom: 0px solid black;
      border-left: 0px solid black;

      &:first-child {
        border-left: 1px solid black;
      }

      input {
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        font-size: inherit;
        width: 100%;
        background-color: transparent;
      }
    }
  }
}
.textCenter {
  text-align: center;
}
.fontBookman {
  font-family: Bookman Old Style;
}
table td, table th table input{
  font-family: Bookman Old Style;
}