.report {
  &__content {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 595px;
  }

  &__buttons {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }

  &__button {
    padding: 5px 10px;
    border: none;
    font-size: 12.1px;
    background-color: #39f;
    color: white;
    font-family: "Bookman Old Style Bold";
    cursor: pointer;
    outline: none;
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__template {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.4;
  }

  &__page {
    position: relative;
    width: 100%;
    height: 841px;
    overflow: hidden;
    // border: none;
    // border-style: outset;
  }

  &__page-wrapper {
    margin: 14px 0px;
    margin-top: 40px;
    width: 100%;
    height: 755px;
    // overflow: hidden;
  }

  &__page-header {
    background-color: black;
    color: white;
    padding: 2px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Bookman Old Style Bold";
    font-size: 10.1px;
  }

  // FIRST PAGE
  &__header {
    padding: 25px 20px 20px 20px;
    display: flex;
    align-items: flex-start;
    height: 90px;
  }

  &__header-logo {
    width: 90px;
    object-fit: contain;
  }

  &__title {
    margin-left: 10px;
    p {
      font-family: "Bookman Old Style";
      font-size: 16px;
    }
  }

  &__inverse-header {
    font-family: "Bookman Old Style";
    background-color: black;
    color: white;
    font-size: 12.1px;
    align-self: flex-end;
    padding: 0.5px 3px;
  }

  &__underline-header {
    color: black;
    font-family: "Bookman Old Style";
    font-size: 12.1px;
    align-self: flex-end;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% + 10px);
      transform: translateX(-50%);
      height: 2px;
      background-color: black;
    }
  }

  &__enrollment-duration {
    position: absolute;
    top: 55px;
    left: 296px;
  }

  &__analysis-time {
    position: absolute;
    top: 55px;
    left: 459px;
  }

  &__form-container {
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__form-row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 9px;
  }

  &__mid-form-container {
    position: absolute;
    z-index: 5;
    left: 0;
    &--1 {
      top: 175px;
    }
    &--2 {
      top: 330px;
    }
    &--3 {
      top: 442px;
    }
    &--4 {
      top: 497px;
    }
  }

  &__mid-form-header {
    background-color: black;
    width: 178px;
    padding: 1px;
    padding-left: 16px;
    p {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-family: "Bookman Old Style Bold";
      color: #fff;
      text-transform: uppercase;
    }
  }

  &__mid-form {
    margin: 5px 0;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 252px;
  }

  &__findingsninterpretation {
    position: absolute;
    left: 14px;
    width: 567px;
    height: 233px;
    top: 568px;
    background-color: transparent;
    display: grid;
    border: 1px solid black;

    display: grid;
    // grid-template-columns: 400px 160px;
  }

  .header {
    background-color: black;
    color: white;
    font-size: 10px;
    font-family: "Bookman Old Style Bold";
    display: inline-block;
    align-self: start;
    font-size: 11px;
    padding: 0px 5px;
  }

  &__findings {
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
  }

  &__interpretation {
    display: flex;
    flex-direction: column;
  }

  &__findings,
  &__interpretation {
    .content {
      width: 98%;
      height: 208px;
      resize: none;
      overflow-x: hidden;
      border: none;
      outline: none;
      font-family: "Bookman Old Style";
      margin: 5px 10px;
    }
  }

  &__interpretation {
    .content {
      font-size: 12.1px;
      width: unset;
      flex: 1;
    }
  }

  &__images {
    position: absolute;
    z-index: 4;
    top: 175px;
    right: 15px;
    width: 330px;
    background-color: rgb(194, 191, 191);
    padding: 5px 15px;
  }

  &__imageContainer {
    margin-bottom: 10px;
  }

  &__image-title {
    font-size: 12.1px;
    border: none;
    font-family: Times, serif;
    outline: none;
    background-color: transparent;
    width: 300px;
  }

  &__image {
    width: 100%;
  }

  &__pie-chart {
    position: absolute;
    right: 15px;
    top: 450px;
    z-index: 4;
    width: 350px;
  }

  // END OF FIRST PAGE STYLES
  &__table-image {
    width: 100%;
    height: 91px;
    object-fit: cover;
  }

  &__hr-image {
    width: 100%;
    position: relative;
    z-index: 1;
    height: 72px;
    object-fit: contain;
    object-position: bottom;

    margin-bottom: 5px;
  }

  // GRAPHS
  &__plots {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__plot {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 10px 0;
  }

  &__plot-title {
    font-size: 12.1px;
    margin-bottom: 0px;
  }

  &__plot-graph {
    width: 100%;
    object-fit: contain;
    height: 150px;
  }

  &__plot-chart {
    width: 100%;
    object-fit: contain;
    height: 177px;
  }

  // ARRYTHMIA PAGES
  &__arrythmia-pages-wrapper {
    margin-top: 33px;
    margin-left: 0;
    margin-right: 0;
  }

  &__arrythmia-header {
    border: none;
    margin-bottom: 3px;
  }

  &__images-wrapper {
    margin: 0 auto;
    width: calc(100% - 28px);
  }

  &__arrythmia-images {
    width: 100%;
    height: 148px;
    object-fit: fill;
    margin: -3px 0;
  }

  // LAST PAGE
  &__finalpage-wrapper {
    padding-top: 20px;
    padding-left: 34px;
  }

  &__finalpage-header {
    position: absolute;
    left: 0;
    top: 45px;
  }

  &__finalpage-description {
    font-family: Times, serif;
    font-size: 12.1px;
    line-height: 1.5;
    margin: 10px 0;
    outline: none;
    border: none;
    width: 100%;
    padding-right: 14px;
    overflow: hidden;
  }

  &__criterias {
    list-style: none;
  }

  &__criteria {
    position: relative;
    font-family: Times, serif;
    font-size: 12.1px;
    line-height: 1.5;
    margin: 3px 0;

    &::after {
      content: "";
      position: absolute;
      left: -20px;
      top: 3px;

      width: 8px;
      height: 8px;
      background-color: black;
    }

    &:hover {
      div {
        display: flex;
      }
    }

    div {
      display: none;
      padding: 5px;
      height: 30px;
      background-color: #f0f0f0;
      border: 1px solid #c9c9c9;
      position: absolute;
      top: -30px;
      left: 0px;

      button {
        font-size: 10px;
        background-color: #39f;
        color: white;
        cursor: pointer;
        border: none;
        text-align: center;
        margin: 0 5px;
        padding: 3px 5px;
      }
    }

    textarea {
      resize: vertical;
      outline: none;
      font-size: 12.1px;
      font-family: Times, serif;
      width: 100%;
      border: none;
      padding-right: 14px;
      // overflow: hidden;
      height: 15px;
    }
  }
}