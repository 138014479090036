.report__images-wrapper-del{
    display: none;
}
.report__hr-image-del{
    display: none;
}
.report__plot-onedel{
    display: none;
}
.report__plot-twodel{
    display: none;
}
/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: -95px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Box */
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal h3 {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .modal div {
    margin-bottom: -3px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-actions button:hover {
    background-color: #0056b3;
  }
  .qc-textarea {
    width: 351px;
    height: 100px;
    border: 0.5px gainsboro solid;
    resize: none;
    margin-left: -35px;
}





.report__buttons * {
  opacity: 0; /* Make the buttons initially transparent */
  visibility: hidden; /* Hide the buttons visually */
  pointer-events: none; /* Prevent interaction when hidden */
  transition: opacity 0.3s ease, visibility 0s linear 0.3s; /* Smooth fade-in and delay hiding visibility */
}

.report__buttons:hover * {
  opacity: 1; /* Make the buttons visible */
  visibility: visible; /* Ensure they are visible */
  pointer-events: auto; /* Enable interaction */
  transition: opacity 0.3s ease, visibility 0s linear 0s; /* Immediate visibility when hovered */
}

