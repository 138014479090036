.uncontrolled-text-area {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  resize: none;
  border: none;
  overflow: hidden;
  outline: none;
  height: 30px;
  background-color: transparent;
  padding-left: 1px;

  // white-space: normal;
  // text-align: center;
}
.dairy-text-area {
  height: 15px;
  font-weight: bold;
  text-align: center;
  font-family: Bookman Old Style;
}